export const AppScreensSet = [
  {
    image: '/front/screens/screen1.png'
  },
  {
    image: '/front/screens/screen2.png'
  },
  {
    image: '/front/screens/screen3.png'
  },
  {
    image: '/front/screens/screen4.png'
  },
  {
    image: '/front/screens/screen5.png'
  },
  {
    image: '/front/screens/screen6.png'
  },
];