import { useSearchParams } from "react-router-dom";
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from "./ResetPassword.module.css"
import Logo from "../../components/Logo/Logo";
import Footer from "../../components/Footer/Footer";
import { useWindowPosition } from "../../functions/useWindowPosition";
import { Helmet } from "react-helmet";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

const ResetPassword = () => {
  const navigate = useNavigate();
  const [scrollPosition] = useWindowPosition();
  const [password, setPassword] = useState([]);
  const [confirmPassword, setConfirmPassword] = useState([]);
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token')
  const { t } = useTranslation();
  console.log(token);

  const handleSubmit = (e) => {
    e.preventDefault();
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ token: token, new_password: password })
    };
    fetch('http://localhost/api/v1/auth/reset-password', requestOptions)
      .then((res) => {
        if (res.status >= 400 && res.status < 600) {
          throw new Error();
        }
        toast.success(t('common.successReset'));
        navigate("/");
      })
      .catch((err) => {
        console.log(err.message);
        toast.error(t('common.defaultError'));
      });
  };

  return (
    <div>
      <Helmet>
        <style>{scrollPosition <= 0 ? 'body { background-color: #1D1628}; }' : 'body { background-color: #0d0c0f}; }'}</style>
      </Helmet>

      <div className={styles.logoContainerDiv}>
        <Logo />
      </div>

      <div className={styles.form}>
        <div className={styles.form_background}>
          <h2 className={styles.h2}>{t('resetForm.title')}</h2>
          <form onSubmit={handleSubmit}>
            <label className={styles.label}>{t('common.password')}</label>
            <input
              className={styles.input}
              type="password"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <label className={styles.label}>{t('resetForm.repeatPassword')}</label>
            <input
              className={styles.input}
              type="password"
              required
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />

            <button className={styles.button}>{t('common.confirm')}</button>
          </form>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default ResetPassword;