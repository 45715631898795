import AppButtons from "../components/AppButtons/AppButtons";
import { useTranslation } from 'react-i18next';
import layoutStyles from "../shared/layout.module.css"
import homeStyles from "./Home.module.css"
import { classNames } from "../functions/utillity";
import GallerySlider from "../components/GallerySlider/GallerySlider"
import { AppScreensSet } from "../components/GallerySlider/AppScreensSet";
import Footer from "../components/Footer/Footer";
import { Helmet } from "react-helmet";
import { useWindowPosition } from "../functions/useWindowPosition";
import Logo from "../components/Logo/Logo";


export default function Home() {
  const [scrollPosition] = useWindowPosition();
  const { t } = useTranslation();

  return (
    <div>
      {/* Solves problem with footer, if we trying to scroll more to bottom,
       default background will be shown, but we want black one */}
      <Helmet>
        <style>{scrollPosition < 100 ? 'body { background-color: #1D1628}; }' : 'body { background-color: #0d0c0f}; }'}</style>
      </Helmet>

      <div className={homeStyles.logoContainerDiv}>
        <Logo />
      </div>

      <section className={homeStyles.sectionAppScreens}>
        <div className={classNames([layoutStyles.flex_horizontal, layoutStyles.flex_center])}>
          <div className={classNames([layoutStyles.flex_center, layoutStyles.half_width])}>
            <div className={homeStyles.mainTitleBlock}>
              <h1 className={homeStyles.bigText}>{t('home.boostLogic')}</h1>
              <p className={homeStyles.titleDescriptionText}>{t('home.boostLogicDescription')}</p>
              <AppButtons />
            </div>
          </div>
          <div className={classNames([layoutStyles.flex_center, layoutStyles.half_width])}>
            <img src="/front/screens/screen2.png" alt="first app screen" className={homeStyles.screenBig} />
            <img src="/front/screens/screen3.png" alt="second app screen" className={homeStyles.screenSmall} />
          </div>
        </div>
      </section>

      <section className={homeStyles.sectionGallery}>
        <GallerySlider slides={AppScreensSet} />
      </section>

      <Footer />
    </div>
  );
}
