import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import { useWindowSize } from '../../functions/useWindowSize';
import styles from "./GallerySlider.module.css"
import { useTranslation } from 'react-i18next';

import 'swiper/css';
import 'swiper/css/pagination';

const GallerySlider = ({ slides }) => {
  const [width, height] = useWindowSize();
  const { t } = useTranslation();
  const [activeSlide, setActiveSlide] = useState([1]);

  return (
    <div className={styles.outerDiv}>
      <div className={styles.sliderInner}>
        <h1 className={styles.feature}>{t(`home.features`)}</h1>
      </div>
      <div className={styles.slider}>
        <Swiper
          slidesPerView={width > 800 ? 3 : 1}
          pagination={true}
          centeredSlides={true}
          modules={[Pagination]}
          loop = {true}
          onSlideChangeTransitionEnd={(swiper) => setActiveSlide(swiper.realIndex + 1)}
        >

        {slides.map((slide, index) => {
          return (
            <SwiperSlide key={index}>
              <img src={slide.image} alt='screenshot' className={styles.image} />
            </SwiperSlide>
          );
        })}
        </Swiper>
      </div>
      <div className={styles.sliderInner}>
        <h2 className={styles.stepTitle}>{t('home.step', { number: activeSlide }) + t(`home.step${activeSlide}`)}</h2>
        <p className={styles.stepDescription}>{t(`home.step${activeSlide}Description`)}</p>
      </div>
    </div>
  );
};

export default GallerySlider;