
import styles from "./Logo.module.css"

export default function Logo() {
    return (
        <a className={styles.a} href="/">
            <div className={styles.logoDiv}>
                <img src="/front/screens/icon_background_transparent.png" className={styles.logoImg} />
                <h2 className={styles.logoText}>UThink</h2>
            </div>
        </a>
    )
}