import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import React from 'react';
import * as ReactDOM from "react-dom/client";
import { ErrorBoundary } from "react-error-boundary";
import { ToastContainer } from 'react-toastify';

import './App.css';
import './i18n';

import Home from './pages/Home';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import Error from "./components/Error/Error";

const router = createBrowserRouter([
  {
    path: "/",
    element: <ErrorBoundary fallback={<Error/>}><Home /></ErrorBoundary>
  },
  {
    path: "reset-password",
    element: <ErrorBoundary fallback={<Error/>}><ResetPassword /></ErrorBoundary>
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
      <React.Suspense fallback="">
        <ToastContainer 
          position="top-center"
          theme="dark"
        />
        <RouterProvider router={router} />
      </React.Suspense>
  </React.StrictMode>
);

