
import styles from "./Error.module.css"
import { useTranslation } from 'react-i18next';

export default function Error() {
    const { t } = useTranslation();

    return (
        <div className={styles.errorDiv}>
            <img src="/front/warning.png" className={styles.img} />
            <p className={styles.text}>{t('common.defaultError')}</p>
        </div>
    )
}