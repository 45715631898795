import styles from "./AppButtons.module.css"
import layoutStyles from "../../shared/layout.module.css"
import { useTranslation } from 'react-i18next';
import { IconContext } from "react-icons"
import { FaApple, FaGooglePlay } from 'react-icons/fa';

function AppButton({ smallText, bigText, icon, href }) {
  return (
    <a className={styles.button} href={href}>
      <IconContext.Provider value={{className: styles.icon}}>
        {icon}
      </IconContext.Provider>
      <p className={styles.smallText}>{smallText}<br /> <span className={styles.bigText}>{bigText}</span></p>
    </a>
  );
}

export default function AppButtons() {
  const { t } = useTranslation();

  return (
    <div className={layoutStyles.flex_horizontal}>
      <AppButton smallText={t('appButtons.availableOn')}
        bigText="App Store"
        icon={<FaApple />}
        href="http:apple.com" />

      <AppButton smallText={t('appButtons.getOn')}
        bigText="Google Play"
        icon={<FaGooglePlay />}
        href="http:google.com" />
    </div>
  );
}
