import styles from "./Footer.module.css"
import { useTranslation } from 'react-i18next';
import { IconContext } from "react-icons"
import { FaYoutube, FaInstagram } from 'react-icons/fa';
import AppButtons from "../AppButtons/AppButtons";
import Logo from "../Logo/Logo";

export default function Footer() {
  const { t } = useTranslation();

  return (
    <footer className={styles.sectionFooter}>
      <div className={styles.cotainerDiv}>
        <Logo />

        <AppButtons />

        <div className={styles.iconsDiv}>
          <a className={styles.iconBack} href="https://www.youtube.com/">
            <IconContext.Provider value={{ className: styles.youtube }}>
              <FaYoutube />
            </IconContext.Provider>
          </a>
          <a href="https://www.instagram.com/">
            <IconContext.Provider value={{ className: styles.instagram }}>
              <FaInstagram />
            </IconContext.Provider>
          </a>
        </div>

        <p className={styles.textRights}>{t('footer.rightsReserved')}</p>
      </div>
    </footer>
  );
}
